const CSS_BREAKPOINTS = {
	MOBILE: 1,
	TABLET: 600,
	TABLET_LANDSCAPE: 810,
	DESKTOP_XSMALL: 1025,
	DESKTOP_SMALL: 1240,
	DESKTOP_BIG: 1440,
	DESKTOP_BIGGER: 1920,
};

const CSS_BREAKPOINT_USED = () => CSS_BREAKPOINTS.DESKTOP_XSMALL;
module.exports = { CSS_BREAKPOINTS, CSS_BREAKPOINT_USED };
