/* eslint-disable react-hooks/rules-of-hooks */
import { TCardPropsCMS } from '../../_core/TypesCardPropsCMS';
import { atomCardBadgeMarkup } from '../../element_atoms/card-badge';
import { generateCardId } from '../../helper_funcs/generateCardId';
import { TSTStateCalltoaction, atomCallToActionMarkup } from '../../element_atoms/call-to-action';
import { hexToRgb } from '../../helper_funcs/hexToRgb';
import { ETabIndexesOrderingLevels } from '@/shared/app/types';

export type TSTState = TCardPropsCMS | any;

export const defaultState: TSTState = {
	link_label: 'Play',
	background_color: '#542C8A',
	background_image_desktop: '',
	background_image_mobile: '',
	title: "The Independent's Jumbo General Knowledge Crossword",
	description: 'Play and win $5 reward',
	shortDescription: '',
	badge: { label: 'New', backgroundColor: '#FFFFFF', labelColor: '#000000' },
	game_slug: 'bubble-shooter',
	game_category: 'Casual',
	game_name: 'Crystal Collapse Crystal Collapse Crystal Collapse',
	game_ext_id: 0,
	game_description:
		'Crystal Collapse: cool game about crystals Crystal Collapse: cool game about crystals Crystal Collapse: cool game about crystals Crystal Collapse: cool game about crystals',
	// OVERRIDES FOR HERO & STANDARD
	override_color_title: '',
	override_hover_block_toggled: 'partial',
	override_hover_block_color_bcg: '',
	override_hover_block_color_text: '',
	override_hover_block_button_text: 'Play',
	override_hover_block_button_text_color: '',
	override_hover_block_button_background_color: '',
	override_hover_block_button_border_color: '',
	graphic_184x400: '',
};

export function renderMarkup(passedState: TSTState = null, window = {}) {
	const state = passedState;
	const id = generateCardId('ark-ui-block-card-portrait');
	/* USED PROPERTIES MAPPING */
	const bgColor: string = state?.background_color || `var(--color-white)`;
	const bgImageDesk: string = state?.background_image_desktop || state?.graphic_184x400 || '';
	const bgImageMob: string = state?.background_image_mobile || state?.graphic_184x400 || '';

	const title: string = state?.title || state?.game_name || '';

	const imgAltText = state?.title ? state?.title : state?.game_name || 'Card icon';

	const accessibilityLabel = ` aria-label="${
		!state?.title && !state?.game_name ? 'Card title' : state?.title || !state?.game_name
	}"`;

	const badge: { label: string; backgroundColor: string; labelColor: string } | null = state?.badge ?? null;

	/* STATE VARIATIONS */
	const imgCoverMobile: string = bgImageMob || bgImageDesk || '';
	const imgCoverDesktop: string = bgImageDesk || bgImageMob || '';

	const useFullAssetRender = () => `
        ${
			imgCoverMobile && state?.onMobile
				? `<img src="${imgCoverMobile}" alt="${imgAltText} ${accessibilityLabel}" class="card_cover __mobile" loading="lazy"/>`
				: ''
		}
        ${
			imgCoverDesktop && !state?.onMobile
				? `<img src="${imgCoverDesktop}" ${accessibilityLabel} alt="${imgAltText}" class="card_cover __desktop" loading="lazy"/>`
				: ''
		}
    `;

	const gameTitle = state?.game_name || '';
	const gameDescr = state?.shortDescription || state?.description || '';

	/* FULL HOVER BUTTON */
	const btnText: string = state?.override_hover_block_button_text || '';
	const isGradientButton =
		state?.override_hover_block_button_background_color_end &&
		state?.override_hover_block_button_background_color_end !== state?.override_hover_block_button_background_color;
	const btnStateBasic: TSTStateCalltoaction = {
		'text': btnText,
		'tabIndex': -1,
		'override_button_normal_color': state?.override_hover_block_button_text_color,
		'override_button_normal_background': state?.override_hover_block_button_background_color,
		'override_button_normal_background_end': state?.override_hover_block_button_background_color_end,
		'override_button_normal_border': state?.override_hover_block_button_border_color,
		'override_button_hover_background': state?.override_hover_block_button_background_color,
		'override_button_hover_background_end': state?.override_hover_block_button_background_color_end,
		'override_button_active_background': state?.override_hover_block_button_background_color,
		'override_button_active_background_end': state?.override_hover_block_button_background_color_end,
		...(!isGradientButton
			? {}
			: {
					override_button_normal_should_use_border: state?.override_hover_block_button_should_use_border,
					override_button_hover_should_use_border: true,
					override_button_hover_border: state?.override_hover_block_button_border_color,
					override_button_active_should_use_border: true,
					override_button_active_border: state?.override_hover_block_button_border_color,
				}),
		'override_is_hover_block': true,
		'icon-url': '/assets/play.svg',
		'icon-position': 'before',
		'size': 'small',
	};
	const btnStateThemeDark: TSTStateCalltoaction = {
		styling: 'outline',
		addClass: `__dark`,
		addToId: '__dark',
		...btnStateBasic,
	};
	const hoverBlockMarkup = () => {
		switch (state?.override_hover_block_toggled) {
			case 'partial':
				return `
                    <div class="card_hover-block" style="background-color: ${
						state?.override_hover_block_color_bcg
							? hexToRgb(state?.override_hover_block_color_bcg) + ` , 0.7)`
							: 'rgba(0, 0, 0, 0.7)'
					};">
                        <p class="card_hover-block_title" style="color: ${
							state?.override_hover_block_color_text || 'var(--color-white)'
						};">${gameTitle}</p>
                        <p class="card_hover-block_description" style="color: ${
							state?.override_hover_block_color_text || 'var(--color-white)'
						};">${gameDescr}</p>
                    </div>
                `;
			case 'full':
				return `
                <div class="card_hover-block __full" style="background-color: ${
					state?.override_hover_block_color_bcg
						? hexToRgb(state?.override_hover_block_color_bcg) + ` , 0.7)`
						: 'rgba(0, 0, 0, 0.7)'
				};" tabindex="${ETabIndexesOrderingLevels.NOINTERACTION}">
                    <div class="card_hover-block_content">
                        <div>
                            <p class="card_hover-block_title" style="color: ${
								state?.override_hover_block_color_text || 'var(--color-white)'
							};">${gameTitle}</p>
                            <p class="card_hover-block_description" style="color: ${
								state?.override_hover_block_color_text || 'var(--color-white)'
							};">${gameDescr}</p>
                        </div>
                        <div class="card_hover-block_button">
                            ${atomCallToActionMarkup(btnStateThemeDark)}
                        </div>
                    </div>
                </div>
                `;
			default:
				return '';
		}
	};
	/* RENDER */

	const html = `
    ${badge && badge?.backgroundColor && badge?.label && atomCardBadgeMarkup(badge?.label, badge?.backgroundColor, badge?.labelColor)}
        <div id="${id}" class="card __full_asset" style="background-color:${bgColor};" tabindex="${ETabIndexesOrderingLevels.NOINTERACTION}">  
            ${useFullAssetRender()}
            ${hoverBlockMarkup()}
        </div>
    `;
	return html;
}
