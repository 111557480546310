import { TCardPropsCMS } from '../../_core/TypesCardPropsCMS';
import { CSS_BREAKPOINT_USED } from '../../global_styles/_breakpoints';
import { renderMarkup as renderStandardCardChild } from '../ark-ui-block-card-standard/_index';
import { getCdnRootIconsLibSlashEnd } from '../../helper_funcs/getCdnRootIconsLib';
import { generateCardId } from '../../helper_funcs/generateCardId';
import { ETabIndexesOrderingLevels } from '@/shared/app/types';

export type TSTState = TCardPropsCMS | any;

export function renderMarkup(passedState: TSTState = {}) {
	const state = passedState;
	const id = generateCardId('ark-ui-block-card-mod-numeric');
	const iconBaseUrlEndSlash = getCdnRootIconsLibSlashEnd(state?.assets_versioned_base_url);
	/* USED PROPERTIES MAPPING */
	const standardCardChildState = { ...state };
	const standardCardChildMarkdown = () =>
		`<div class="ark-ui-block-card-standard">${renderStandardCardChild(standardCardChildState)}</div>`;
	/* MODIFICATIONS COMBOS */
	/* RENDER */
	const cardNumber: number = state?.numeric_card_number || 1;
	const dynamicClasses = ``;
	/// TODO: Align with design team for webfonts approach
	const renderNumbers = () => `
            <img src="${iconBaseUrlEndSlash}${cardNumber}.svg" alt="${cardNumber}" class="number number_${cardNumber}" loading="lazy" />
        `;
	const html = `
        <div id="${id}" class="ark-ui-block-card-mod-numeric-card${dynamicClasses}${
			!cardNumber ? '' : ' __' + cardNumber
		}" tabindex="${ETabIndexesOrderingLevels.NOINTERACTION}">
            ${generateDynamicStyles()}
            ${renderNumbers()}
            ${standardCardChildMarkdown()}
        </div>
    `;
	return html;

	// DYNAMIC STYLES
	function generateDynamicStyles() {
		const nums = Array.from({ length: 10 }, (_, i) => i + 1);
		return `
            <style>
                /* component-bound CSS variables and styles here */
                #${id} { /* mobile first */
                    --card-numeric-actionbar-height: 2rem;
                }
                @media (min-width: ${CSS_BREAKPOINT_USED()}px) {
                    #${id} { /* desktop */
                    }
                }
            </style>
        `;
	}
}
